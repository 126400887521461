import React from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/design.module.css"
import Video from "../components/video"
import PS from "../../static/images/ps.png"
import AE from "../../static/images/ae.png"
import FIG from "../../static/images/figma.png"
import ILL from "../../static/images/ill.png"
import LR from "../../static/images/lr.png"
import PPRO from "../../static/images/prpro.png"

export default function Design() {
  return (
    <Layout>
      <div className={styles.devIcons}>
        <img src={PS} alt="photoshop" height="60px" width="auto"></img>
        <img src={ILL} alt="illustrator" height="60px" width="auto"></img>
        <img src={AE} alt="after effects" height="60px" width="auto"></img>
        <img src={PPRO} alt="premiere pro" height="60px" width="auto"></img>
        <img src={FIG} alt="figma" height="60px" width="auto"></img>
        <img src={LR} alt="light room" height="60px" width="auto"></img>
      </div>
      <section className={styles.header}>
        <div className={styles.mainText}>
          <h2>Bias is Universal</h2>
          <h2>Explainer Video</h2>
          <p>
            Bias is universal, an explainer and exploration into character
            style.
          </p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/716155512"
              videoTitle="bias is universal"
              videoWidth="100%"
              videoHeight="200px"
              videoMinWidth="200px"
            />
          </div>
        </div>
        <div className={styles.mainText}>
          <h2>Monotone Character</h2>
          <h2>Concept Video</h2>
          <p>
            An exploration of monotone and character style. Motion Graphics,
            rigging, and other animations were done in After Effects.
          </p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/716181292"
              videoTitle="Character Monotone Exploration"
              videoWidth="100%"
              videoHeight="200px"
              videoMinWidth="200px"
            />
          </div>
        </div>
        <div className={styles.mainText}>
          <h2>Wellness Character</h2>
          <h2>Concept Video</h2>
          <p>
            An exploration of character style for the wellness offering. Motion
            Graphics, rigging, and other animations were done in After Effects.
          </p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/716255915"
              videoTitle="Character Exploration"
              videoWidth="100%"
              videoHeight="200px"
              videoMinWidth="200px"
            />
          </div>
        </div>
        <div className={styles.mainText}>
          <h2>Diversity</h2>
          <h2>Motion Graphics</h2>
          <p>A motion graphic explainer dealing with diversity.</p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/720087554"
              videoTitle="Diversity"
              videoWidth="100%"
              videoHeight="200px"
              videoMinWidth="200px"
            />
          </div>
        </div>
        <div className={styles.mainText}>
          <h2>Content Ecosystem</h2>
          <h2>Explainer for Apps</h2>
          <p>
            A motion graphic explainer created for the engineering team to
            introduce a network of internal web-applications that help the
            workflow for video creation.
          </p>
          <div className={styles.vid}>
            <Video
              videoSrcURL="https://player.vimeo.com/video/720090824"
              videoTitle="Diversity"
              videoWidth="100%"
              videoHeight="200px"
              videoMinWidth="200px"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}
